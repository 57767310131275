import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../../db/dataProvider';
import './resultado.css';
import DomicilioFiscal from '../factura/DomicilioFiscal';

function ResultadoPage() {
  const location = useLocation();
  const [generationStatus, setGenerationStatus] = useState('Generando Factura...');
  const [pdfUrl, setPdfUrl] = useState('');
  const [xmlUrl, setXmlUrl] = useState('');
  const [xmlFile,setXmlFile] =useState('');
  const [counter, setCounter] = useState(null); // Initialize counter to null
  const { receptor, ticket, branch } = useContext(DataContext);
  const [comprobanteData,setComprobanteData] = useState({
    uuid:'',
    receptor:'',
    emisor:'',
    receptorName: '',
    receptorRFC: '',
    postalCode: '',
    total:0,
    email: '', 

  });
  
  const navigate = useNavigate();

  const [capturedData, setCapturedData] = useState({
    receptorName: '',
    receptorRFC: '',
    postalCode: '',
    fiscalRegime: '',
    email: '',
  });

  useEffect(() => {
    if (!location.state) {
      console.error('location.state is null');
      setGenerationStatus('Error: No data provided.');
      return;
    }

    setCapturedData({
      receptorName: receptor?.nombreReceptor?.value || '',
      receptorRFC: receptor?.rfc?.value || '',
      postalCode: receptor?.domicilioFiscal?.value || '',
      fiscalRegime: receptor?.regimenFiscal?.label || '',
      email: receptor?.email?.value || '',
    });

    function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
      const base64ContentArray = b64Data.split(',');
      if (base64ContentArray.length > 1) {
        b64Data = base64ContentArray[base64ContentArray.length - 1];
      }

      b64Data = b64Data.replace(/[^A-Za-z0-9\+\/]/g, '');
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    const fetchData = async () => {
      const requestData = {
        ...location.state,
        receptor,
        ticket,
        branch,
      };

      try {
        const response = await axios.post(branch.endpoints.timbrar, requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response && response.data) {
          setGenerationStatus('Timbrando, procesando respuesta... ');

          if (response.data.pdf) {
            const pdfBlob = b64toBlob(response.data.pdf, 'application/pdf');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfUrl);
          }

          if (response.data.xml) {
            const xmlBlob = new Blob([response.data.xml], { type: 'application/xml' });
            const xmlUrl = URL.createObjectURL(xmlBlob);
            setXmlFile(response.data.xml);
             //console.log("----->"+response.data.xml);

            setXmlUrl(xmlUrl);
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(response.data.xml, 'application/xml');
            const cfdiNamespace = 'http://www.sat.gob.mx/cfd/4';
            const tfdNamespace = 'http://www.sat.gob.mx/TimbreFiscalDigital';

            const emisorElement = xmlDoc.getElementsByTagNameNS(cfdiNamespace, 'Emisor')[0];
            const receptorElement = xmlDoc.getElementsByTagNameNS(cfdiNamespace, 'Receptor')[0];
            
            const comprobanteElement = xmlDoc.getElementsByTagNameNS(cfdiNamespace, 'Comprobante')[0];
            const timbreElement = xmlDoc.getElementsByTagNameNS(tfdNamespace, 'TimbreFiscalDigital')[0];


            if (timbreElement){
              console.log(timbreElement.getAttribute('UUID'));
            }

            if (emisorElement) {

              console.log(emisorElement.getAttribute('Nombre') || '');
            }
            if (comprobanteElement) {
              console.log(comprobanteElement.getAttribute('Total') || '');
            }
            if(receptorElement)
            {
              console.log(receptorElement.getAttribute('Nombre') || '');

              console.log(receptorElement.getAttribute('DomicilioFiscalReceptor')||'');
              console.log(receptorElement.getAttribute('RegimenFiscalReceptor') || '' );
            }
        
            setComprobanteData({
              uuid:timbreElement.getAttribute('UUID'),
              receptor:receptorElement.getAttribute('Nombre') ,
              emisor:emisorElement.getAttribute('Nombre'),
              receptorName: receptorElement.getAttribute('Nombre'),
              receptorRFC: receptorElement.getAttribute('Rfc'),
              postalCode: receptorElement.getAttribute('DomicilioFiscalReceptor'),
              total:comprobanteElement.getAttribute('Total') ,
              email: '', 
          
            });



          }
          setGenerationStatus(response.data.message);
        } else {
          console.error('Response or response.data is undefined');
          setGenerationStatus('Error: Unexpected response format.');
        }
      } catch (error) {
        console.error('Error fetching data:', error.response?.data?.message || error.message);
        setGenerationStatus('Error tratando de timbrar la factura -> ' + error.response?.data?.message);

        // Start counter for automatic navigation in case of error
        setCounter(30);
        const interval = setInterval(() => {
          setCounter((prevCounter) => {
            if (prevCounter <= 1) {
              clearInterval(interval);
              navigate('/factura', { state: location.state });
            }
            return prevCounter - 1;
          });
        }, 1000);

        return () => clearInterval(interval); // Cleanup interval on unmount
      }
    };

    fetchData();
  }, [location.state, receptor, ticket, branch, navigate]);

  const cancelNavigation = () => {
    if (pdfUrl && xmlFile) {
      // Navigate to CancelarPage if there are documents to download
      navigate('/cancelar', { state: { xmlFile } });
    } else {
      // If no documents, just stop the counter
      setCounter(null);
    }
  };

  if (!location.state) {
    return <div className="resultado-page-error">Acceso inválido o sesión expirada. Por favor, navegue desde la página correcta.</div>;
  }

  return (
    <div className="resultado-page-container">
      <h1 className="resultado-page-title">Resultado de la Operación</h1>
      <p className="resultado-page-status">
        Estado: <strong>{generationStatus}</strong>
      </p>
      {counter !== null && (
        <p className="resultado-page-counter">
          Regresando a la página anterior en {counter} segundos...
        </p>
      )}
      <button onClick={cancelNavigation} className="resultado-page-cancel-button">
        Cancelar
      </button>
      <div className="resultado-page-download">
        {pdfUrl && (
          <a href={pdfUrl} download className="resultado-page-download-link pdf-link">
            Descargar PDF
          </a>
        )}
        {xmlUrl && (
          <a href={xmlUrl} download className="resultado-page-download-link xml-link">
            Descargar XML
          </a>
        )}
      </div>
      <div className="resultado-page-info">
        <h2>Información del comprobante:</h2>
        <div className="resultado-page-info-item"><strong>UUID:</strong> {comprobanteData.uuid}</div>
        
        <div className="resultado-page-info-item"><strong>Nombre del Receptor:</strong> {capturedData.receptorName||comprobanteData.receptorName}</div>
        <div className="resultado-page-info-item"><strong>RFC del Receptor:</strong> {capturedData.receptorRFC||comprobanteData.receptorRFC}</div>
        <div className="resultado-page-info-item"><strong>Código Postal:</strong> { capturedData.postalCode|| comprobanteData.postalCode}</div>
        <div className="resultado-page-info-item"><strong>Régimen Fiscal:</strong> {capturedData.regimenFiscal|| comprobanteData.regimenFiscal}</div>
        <div className="resultado-page-info-item"><strong>Email:</strong> {capturedData.email}</div>
      </div>
    </div>
  );
}

export default ResultadoPage;
