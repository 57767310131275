import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Nombre.css';

function Nombre({ nr, setNR }) {
  const [value, setValue] = useState(nr.value || '');
  const [isValid, setIsValid] = useState(nr.isValid || false);
  const [error, setError] = useState('');

  useEffect(() => {
    setValue(nr.value || '');
    setIsValid(nr.isValid || false);
  }, [nr]);

  const handleChange = (event) => {
    let newValue = event.target.value.toUpperCase();
    const hasTrailingSpace = newValue.endsWith(' ');
    //newValue = newValue.replace(/\s+/g, ' ');

    //setValue(newValue);

    const nameRegex = /^[A-ZÑ0-9\s&,\-d"]*$/;
    const isValidInput = newValue.trim() !== '' && nameRegex.test(newValue) && !hasTrailingSpace;

    if (newValue.trim() === '') {
      setError('El campo no puede estar vacio.');
    } else if (!nameRegex.test(newValue)) {
      setError('El campo tiene valores no aceptados.');
    } else if (hasTrailingSpace) {
      setError('No debe tener espacios al final o al principio, ni dobles espacios.');
    } else {
      setError('');
    }

    setIsValid(isValidInput);
    setNR({ value: newValue, isValid: isValidInput });
  };

  return (
    <div className="form-group">
      <input
        type="text"
        className={`form-control nombre-input ${isValid ? 'is-valid' : 'is-invalid'}`}
        placeholder="Nombre or Razón Social"
        value={value}
        onChange={handleChange}
        style={{ textTransform: 'uppercase' }}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
}

export default Nombre;
