import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../../db/dataProvider';
import './resultado.css';

function ResultadoPage() {
  const location = useLocation();
  const [facturaStatus, setFacturaStatus] = useState('Generando Factura...');
  const [cancelStatus, setCancelStatus] = useState('');
  const { receptor, ticket, branch } = useContext(DataContext);
  const navigate = useNavigate();

  const [invoiceData, setInvoiceData] = useState({
    UUID: '',
    Emisor: '',
    Receptor: '',
    RFC_receptor: '',
    RFC_emisor:'',
    Total: '',
    FechaTimbrado:''
  });
  
  useEffect(() => {
    if (!location.state) {
      console.error('location.state is null');
      setFacturaStatus('Error: No data provided.');
      return;
    }

    
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(location.state.xmlFile, 'application/xml');
    const cfdiNamespace = 'http://www.sat.gob.mx/cfd/4';
    const tfdNamespace = 'http://www.sat.gob.mx/TimbreFiscalDigital';

    const emisorElement = xmlDoc.getElementsByTagNameNS(cfdiNamespace, 'Emisor')[0];
    const receptorElement = xmlDoc.getElementsByTagNameNS(cfdiNamespace, 'Receptor')[0];
    const comprobanteElement = xmlDoc.getElementsByTagNameNS(cfdiNamespace, 'Comprobante')[0];
    const timbreElement = xmlDoc.getElementsByTagNameNS(tfdNamespace, 'TimbreFiscalDigital')[0];

    setInvoiceData({
      UUID: timbreElement?.getAttribute('UUID') || '',
      Emisor: emisorElement?.getAttribute('Nombre') || '',
      RFC_emisor:emisorElement?.getAttribute('Rfc') || '',
      Receptor: receptorElement?.getAttribute('Nombre') || '',
      RFC_receptor: receptorElement?.getAttribute('Rfc') || '',
      Total: comprobanteElement?.getAttribute('Total') || '',
      FechaTimbrado:timbreElement?.getAttribute('FechaTimbrado') || '',
    });

   const   actualizaStatus = async () => {
      try {
        //console.log(branch.endpoints.post+'/facturas/status');
        const response =  await axios.post(branch.endpoints.post+'/facturas/status', invoiceData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        //console.log("respuesta_"+response)
        if (response) {
  
          setFacturaStatus('Verificando el status, procesando respuesta... ');
          setFacturaStatus(response.data.message);

        } else {
          console.error('Response or response.data is undefined');
          setFacturaStatus('Error: Unexpected response format.');
        }
      } catch (error) {
        console.log("El error es ->"+error);
        console.error('Error fetching data:', error.response?.data?.message || error.message);
        setFacturaStatus('Error tratando de timbrar la factura -> ' + error.response?.data?.message);
      }
  
  
      console.log("status actualizado");
    }



    actualizaStatus();

/*
    const fetchData = async () => {
      const requestData = {
        ...location.state,
        receptor,
        ticket,
        branch,
      };

      try {
        console.log(branch.endpoints.post+'/facturas/timbrar/cancelar');
        const response = await axios.post(branch.endpoints.post+'/facturas/timbrar/cancelar', invoiceData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response && response.data) {
          setFacturaStatus('Cancelando, procesando respuesta... ');
          setFacturaStatus(response.data.message);
        } else {
          console.error('Response or response.data is undefined');
          setFacturaStatus('Error: Unexpected response format.');
        }
      } catch (error) {
        console.error('Error fetching data:', error.response?.data?.message || error.message);
        setFacturaStatus('Error tratando de timbrar la factura -> ' + error.response?.data?.message);
      }
    };

    fetchData();*/
  }, [location.state, receptor, ticket, branch]);

  const cancelarFactura = async () => {
    try {
      setCancelStatus('Cancelando factura...');
      console.log(branch.endpoints.post+'/facturas/timbrar/cancelar');
      const response = await axios.post(branch.endpoints.post+'/facturas/timbrar/cancelar', invoiceData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setCancelStatus(response.data.message || 'Factura cancelada con éxito');
    } catch (error) {
      console.error('Error canceling factura:', error.response?.data?.message || error.message);
      setCancelStatus('Error al cancelar la factura: ' + error.response?.data?.message);
    }
  };

  const handleRegresar = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="resultado-page-container">
      <h1 className="resultado-page-title">Factura a Cancelar</h1>
      <div className="resultado-status-box">
        <h2>Status de la factura:</h2>
        <p><strong>{facturaStatus}</strong></p>
      </div>
      <div className="resultado-page-info-box">
        <h2>Información en el CFDI:</h2>
        <div><strong>UUID:</strong> {invoiceData.UUID}</div>
        <div><strong>Fecha Timbrado:</strong> {invoiceData.FechaTimbrado}</div>
        
        <div><strong>Emisor:</strong> {invoiceData.Emisor}</div>
        <div><strong>Receptor:</strong> {invoiceData.Receptor}</div>
        <div><strong>RFC:</strong> {invoiceData.RFC_receptor}</div>
        <div><strong>Total:</strong> {invoiceData.Total}</div>
      </div>
      <div className="resultado-page-actions">
        <button onClick={cancelarFactura}>Cancelar Factura</button>
        <button onClick={handleRegresar}>Regresar</button>
      </div>
      {cancelStatus && <p className="resultado-page-cancel-status">Estado de cancelación:<strong>{cancelStatus}</strong> </p>}
    </div>
  );
}

export default ResultadoPage;
